import React from "react";
import useHasPermission from "shared/hooks/useHasPermission";
import styled from "styled-components";
import TraceconttonLogo from "../../res/img/Trace_Transparencia.png";
import { pageSwitch as ConferenciaCampo } from "app/ConferenciaCampo/screens/Fazendas/pageSwitch";

function Home() {

  const hasPermissionRelatorioConfCampo = useHasPermission('relatorios.conferencia-do-campo');
  
  return (
    <>
      {
        hasPermissionRelatorioConfCampo
          ? <ConferenciaCampo />
          : (
            <Container>
              <Image aria-label="tracecotton-logo" src={TraceconttonLogo} />
            </Container>
          )
      }
    </>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  max-height: 100vh;
  width: 100%;
`;

const Image = styled.img`
  margin: auto;
  max-height: 100%;
`;

export default Home;
